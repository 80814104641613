<template>
  <div class="landing-container w-full relative">
    <div
      class="h-full md:rounded-[70px] md:max-w-[1200px] mx-auto flex flex-col items-center md:pb-0 mt-[24px] md:mt-[128px]"
      :class="{
        'h-screen justify-center !mt-0': isApp
      }">
      <div v-motion :initial="{
        scale: 0.4,
        opacity: 0,
        y: 50
      }" :enter="{
        scale: 1,
        opacity: 1,
        y: 0,
        transition: {
          type: 'spring',
          stiffness: 260,
          damping: 60,
          mass: 8
        }
      }"
        class="opacity-0 scale-[0.2] min-h-[220px] w-[270px] md:h-[160px] md:w-[600px] flex flex-col items-center justify-center relative">
        <div
          class="hero-ball absolute top-[60px] bg-background min-w-[380px] min-h-[380px] rounded-full scale-[0.7] md:scale-100">
          <AnimatedHeroIcon class="absolute h-[65px] left-[-80px] top-[40px]" src="/images/landing/hero/1.png" />
          <AnimatedHeroIcon :delay="300" class="absolute h-[65px] left-[-20px] top-[-50px]"
            src="/images/landing/hero/2.png" />
          <AnimatedHeroIcon :delay="600" class="absolute h-[70px] left-[70px] top-[-105px]"
            src="/images/landing/hero/3.png" />
          <AnimatedHeroIcon :delay="900" class="absolute h-[70px] right-[75px] top-[-105px]"
            src="/images/landing/hero/4.png" />
          <AnimatedHeroIcon :delay="1200" class="absolute h-[65px] right-[-20px] top-[-50px]"
            src="/images/landing/hero/5.png" />
          <AnimatedHeroIcon :delay="1500" class="absolute h-[65px] right-[-80px] top-[40px]"
            src="/images/landing/hero/6.png" />
          <div class="hero-gradient top-[160px] absolute w-[200%] -left-1/2 h-[300px]" />
        </div>
      </div>
      <div class="pt-4 md:pt-[40px] relative -top-[70px] z-10 flex flex-col items-center mt-6 md:mt-0">
        <h1 v-motion :initial="{
          scale: 0.6,
          opacity: 0,
          y: -50
        }" :enter="{
          scale: 1,
          opacity: 1,
          y: 0,
          transition: {
            type: 'spring',
            mass: 3, stiffness: 150, damping: 20
          }
        }" class="opacity-0 max-w-[720px] font-serif font-bold text-[32px] md:text-[48px] text-center my-2 md:my-4">
          Estude com estratégia e domine o ENEM
        </h1>
        <p v-for="(perk, i) in perks" :key="perk" v-motion :initial="{
          opacity: 0,
          y: 10
        }" :enter="{
          opacity: 1,
          y: 0,
          transition: {
            type: 'spring'
          }
        }" :delay="(i * 100) + 300"
          class="opacity-0 max-w-[700px] inline-block text-[16px] md:text-[20px] text-center mt-[6px]">
          <span v-html="perk"></span>
        </p>
        <div v-motion :initial="{
          opacity: 0,
          scale: 0.8,
          y: -10
        }" :enter="{
          opacity: 1,
          scale: 1,
          y: 0,
          transition: {
            type: 'spring',
            stiffness: 250,
            damping: 25,
            mass: 5,
          }
        }" :delay="1000" class="max-w-[500px] w-full opacity-0 relative">
          <NuxtLink draggable="false" to="/cadastrar">
            <UiButton @click="useOpTrack('landing_home_cta', {
              button: 'hero'
            })" size="lgHeight" width="full" class=" mt-[30px]">
              Começar gratuitamente
            </UiButton>
          </NuxtLink>

          <NuxtLink v-if="isApp" to="/entrar">
            <UiButton size="xs" variant="flat" bg-color="white" width="full" class="mt-4">
              Já tenho uma conta
            </UiButton>
          </NuxtLink>
        </div>
      </div>
    </div>

    <div v-motion :initial="{
      opacity: 0,
      y: 10
    }" :enter="{
      opacity: 1,
      y: 0,
      transition: {
        type: 'spring',
        damping: 75,
      }
    }" v-if="!isApp" :delay="1500" class="opacity-0 relative w-[280px] mx-auto top-[-20px]">
      <img src="/images/icons/laurel.svg" class="w-[280px] h-[80px]" />
      <div class="absolute flex flex-col gap-3 justify-center items-center top-0 left-0 w-full h-full">
        <div class="flex gap-2">
          <img v-for="i in 5" :key="i" src="/images/icons/star.png" class="size-6" />
        </div>
        <div>
          #1 preparação ENEM
        </div>
      </div>

      <!-- iOS Banner -->
      <a class="hidden md:block absolute left-0 top-0 -translate-x-full pr-14 mt-4" @click="useOpTrack('landing_download_cta', {
        button: 'ios_hero'
      })" href="https://apps.apple.com/us/app/respostacerta-quest%C3%B5es-enem/id6740682044" target="_blank"
        rel="noopener noreferrer">
        <img src="/images/icons/download-apple.svg" alt="Baixar na App Store" class=" w-[170px]" />
      </a>

      <!-- Android Banner -->
      <a class="hidden md:block absolute right-0 top-0 translate-x-full pl-14 mt-4" @click="useOpTrack('landing_download_cta', {
        button: 'android_hero'
      })" href="https://play.google.com/store/apps/details?id=com.grqtech.respostacerta" target="_blank"
        rel="noopener noreferrer">
        <img src="/images/icons/download-android.png" alt="Baixar no Google Play" class="w-[176px]" />
      </a>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { UiButton } from "~/components/ui";
import AnimatedHeroIcon from "./animated-hero-icon.vue";
import { sendPlausibleEvent } from "~/libs/plausible";

const isApp = useIsApp();

const perks = [
  '📚 Estude com <b>questões ajustadas ao seu nível</b>',
  '🧠 Aprenda com <b>resoluções passo a passo</b>',
  '🎯 Confira sua <b>nota TRI</b> oficial',
  '📝 Treine com <b>simulados</b> como no dia da prova',
  '📊 Descubra seus <b>pontos fortes e fracos</b>'
]
</script>

<style lang="sass" scoped>
.hero-ball
  box-shadow: 0px -4px 20px 29px rgba(255, 230, 0, 0.1)
.hero-gradient
  background: linear-gradient(180deg, rgba(255, 254, 244, 0) 0%, #FFFEF4 52.5%)

</style>
