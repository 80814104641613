<template>
  <div class="landing-container w-full relative">
    <div
      class=" md:rounded-[70px] md:max-w-[1200px] mx-auto flex flex-col items-center md:pb-0 md:mt-[128px] h-screen justify-center">

      <Motion :initial="{ opacity: 0, y: 50, scale: 0.6 }" :animate="{ opacity: 1, y: 0, scale: 1 }"
        :transition="{ type: 'spring', stiffness: 80, damping: 15 }"
        class="min-h-[220px] w-[270px] md:h-[160px] md:w-[600px] flex flex-col items-center justify-center relative">
        <div
          class="hero-ball absolute top-[60px] bg-background min-w-[380px] min-h-[380px] rounded-full scale-[0.7] md:scale-100">
          <AnimatedHeroIcon class="absolute h-[75px] left-[-80px] top-[40px]" src="/images/landing/hero/1.png" />
          <AnimatedHeroIcon :delay="150" class="absolute h-[75px] left-[-20px] top-[-50px]"
            src="/images/landing/hero/2.png" />
          <AnimatedHeroIcon :delay="300" class="absolute h-[80px] left-[70px] top-[-105px]"
            src="/images/landing/hero/3.png" />
          <AnimatedHeroIcon :delay="450" class="absolute h-[80px] right-[75px] top-[-105px]"
            src="/images/landing/hero/4.png" />
          <AnimatedHeroIcon :delay="600" class="absolute h-[65px] right-[-20px] top-[-50px]"
            src="/images/landing/hero/5.png" />
          <AnimatedHeroIcon :delay="750" class="absolute h-[65px] right-[-80px] top-[40px]"
            src="/images/landing/hero/6.png" />
          <div class="hero-gradient top-[160px] absolute w-[200%] -left-1/2 h-[300px]" />
        </div>
      </Motion>

      <div class="pt-8 md:pt-[40px] relative -top-[70px] z-10 flex flex-col items-center mt-6 md:mt-0">
        <Motion :initial="{ opacity: 0, scale: 0.90, y: -20 }" :animate="{ opacity: 1, scale: 1, y: 0 }"
          :transition="{ delay: 0.1, type: 'spring', stiffness: 150, damping: 12 }">
          <h1 class="max-w-[720px] font-serif font-bold text-[28px] text-center my-2 md:my-4 text-black">
            O jeito rápido e eficaz de aumentar sua nota no ENEM
          </h1>
        </Motion>

        <Motion as="p" :initial="{ opacity: 0 }" :animate="{ opacity: 1 }" :transition="{ duration: 0.6, delay: 0.5 }"
          class="text-center font-medium text-base leading-5">
          Questões com resoluções detalhadas, simulados personalizados e nota com TRI oficial.
        </Motion>

        <Motion as="div" :initial="{ opacity: 0, y: -10 }" :animate="{ opacity: 1, y: 0 }"
          :transition="{ type: 'spring', stiffness: 30, damping: 12, delay: 0.7 }"
          class="max-w-[500px] w-full relative mt-8">
          <AppleSignIn v-if="platform === 'ios'" />
          <GoogleSignIn v-else />

          <NuxtLink to="/entrar">
            <button class="text-primary w-full h-10 mt-2">
              Entrar com e-mail
            </button>
          </NuxtLink>

          <!-- <div class="flex items-center justify-center">
            <button @click="teste">
              Teste
            </button>
          </div> -->
        </Motion>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import AppleSignIn from "~/domains/auth/components/apple-sign-in.vue";
import GoogleSignIn from "~/domains/auth/components/google-sign-in.vue";
import AnimatedHeroIcon from "./animated-hero-icon.vue";
import { Capacitor } from '@capacitor/core';

const platform = Capacitor.getPlatform()
</script>

<style lang="sass" scoped>
.hero-ball
  box-shadow: 0px -4px 20px 29px rgba(255, 230, 0, 0.1)
.hero-gradient
  background: linear-gradient(180deg, rgba(255, 254, 244, 0) 0%, #FFFEF4 52.5%)

</style>
