<template>
  <div class="bg-background relative">
    <HeroApp v-if="isApp" />

    <div v-if="!isApp">
      <Hero />
      <div class="my-10 md:my-[100px]">
        <Social />
      </div>
      <Features class="my-20 md:my-[200px]" />

      <Test />

      <BottomCTA class="md:mt-[200px]" />
      <Footer />
    </div>

    <ClientOnly v-if="!isApp">
      <MobileBanner />
    </ClientOnly>

    <div class="bg-background h-screen w-full fixed top-0 z-[-1]" />
  </div>
</template>

<script lang="ts" setup>
import Hero from '~/components/landing/hero.vue'
import Social from '~/components/landing/social.vue'
import Features from '~/components/landing/features.vue'
import BottomCTA from '~/components/landing/bottom-cta.vue'
import Footer from '~/components/landing/footer.vue'
import Test from '~/components/landing/test.vue'
import MobileBanner from '~/components/landing/mobile-banner.vue'
import HeroApp from '~/components/landing/hero-app.vue'

const isApp = useIsApp();

useHead({
  title:
    "respostaCerta - O jeito rápido, eficaz e gratuito de aumentar sua nota no ENEM",
});
definePageMeta({
  layout: "landing",
});
</script>
